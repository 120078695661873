import * as React from "react";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import FaqSection from "../../sections/Faq/FaqSection";
import FaqList from "../../sections/Faq/FaqList";
import Footer from "../../components/Footer";

const Faq = () => {
  return (
    <>
      <Header />
      <PageLayout title="FAQ">
        <FaqSection />
        <FaqList />
        <Footer />
      </PageLayout>
    </>
  );
};

export default Faq;
